dmx.Component('bs4-collapse', {

    initialData: {
        collapsed: true
    },

    attributes: {
        show: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        toggle: function() {
            jQuery(this.$node).collapse('toggle');
        },

        show: function() {
            jQuery(this.$node).collapse('show');
        },

        hide: function() {
            jQuery(this.$node).collapse('hide');
        }
    },

    events: {
        show: Event,
        shown: Event,
        hide: Event,
        hidden: Event
    },

    render: function(node) {
        this.$node = node;
        this.$parse();

        jQuery(node).on('show.bs.collapse', this.dispatchEvent.bind(this, 'show'));
        jQuery(node).on('shown.bs.collapse', this.dispatchEvent.bind(this, 'shown'));
        jQuery(node).on('hide.bs.collapse', this.dispatchEvent.bind(this, 'hide'));
        jQuery(node).on('hidden.bs.collapse', this.dispatchEvent.bind(this, 'hidden'));

        jQuery(node).on('shown.bs.collapse', this.onShown.bind(this));
        jQuery(node).on('hidden.bs.collapse', this.onHidden.bind(this));

        jQuery(node).addClass('collapse');

        jQuery(this.$node).collapse({ toggle: false });

        this.update({});
    },

    onShown: function() {
        this.set('collapsed', false);
    },

    onHidden: function() {
        this.set('collapsed', true);
    },

    update: function(props) {
        if (props.show != this.props.show) {
            jQuery(this.$node).toggleClass('show', this.props.show);
            this.set('collapsed', !this.props.show);
        }
    },

    beforeDestroy: function() {
        jQuery(this.$node).off('.bs.collapse');
        jQuery(this.$node).collapse('dispose');
    }

});
